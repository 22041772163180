import { VERSION } from '@angular/core';
import Bugsnag from '@bugsnag/js';
class BugsnagErrorHandler {
  bugsnagClient;
  constructor(client) {
    if (client) {
      this.bugsnagClient = client;
    } else {
      this.bugsnagClient = Bugsnag._client;
    }
    // Add angular runtime to device metadata
    const device = {
      runtimeVersions: {
        angular: VERSION.full
      }
    };
    this.bugsnagClient.addOnSession(session => {
      session.device = {
        ...session.device,
        ...device
      };
    });
    this.bugsnagClient.addOnError(event => {
      event.device = {
        ...event.device,
        ...device
      };
    });
  }
  handleError(error) {
    const handledState = {
      severity: 'error',
      severityReason: {
        type: 'unhandledException'
      },
      unhandled: true
    };
    const event = this.bugsnagClient.Event.create(error, true, handledState, 'angular error handler', 1);
    this.bugsnagClient._notify(event);
    // Replicate the default behaviour of the angular error handler by calling console.error
    // previously this called ErrorHandler.prototype.handleError but this caused a mismatch between
    // the compiled code and the angular version used in the application.
    console.error(error);
  }
}

// zones are optional, so we need to detect if they are being used
// see https://angular.io/guide/zone#noopzone
const isNgZoneEnabled = typeof Zone !== 'undefined' && !!Zone.current;
const plugin = {
  name: 'Angular',
  load: client => {
    const originalNotify = client._notify;
    client._notify = function () {
      const event = arguments;
      if (isNgZoneEnabled) {
        // run notify in the root zone to avoid triggering change detection
        Zone.root.run(() => {
          originalNotify(event);
        });
      } else {
        // if zones are not enabled, change detection will not run anyway
        originalNotify(event);
      }
    };
    return new BugsnagErrorHandler(client);
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { BugsnagErrorHandler, plugin };
